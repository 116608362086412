@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --primary-branding-color: #fa8c16 !important;
  --primary-branding-border-color: #fa8c16 !important;
  --hyper-link-hover-color: #fa8c16 !important;
  --usermention-text-color: #fa8c16 !important;
  --radio-button-bg-active-color: #fa8c16 !important;
  --radio-button-bg-active-color: #fa8c16 !important;
  --designer-primary-border-color: #fefefe !important;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: rotate 2s linear infinite;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
